<template>
  <div id="generalInfo" class="">
    <v-form ref="form" v-model="valid" v-on:submit.prevent="savecourses">
      <!-- Row course  -->
      <div v-if="coursesObject.length > 0">
        <div v-for="(item, index) in coursesObject" :key="index">
          <div class="remove-icon text-right">
            <v-icon
              @click="deleteCourse(index)"
              style="color: #e1e1e1; cursor: pointer"
            >
              delete
            </v-icon>
          </div>
          <v-row class="form-item">
            <!-- Course Name -->
            <v-col cols="12" md="12">
              <div>
                <p class="value">{{ $t("Course Name") }}</p>
              </div>
              <v-text-field
                v-model="item.course_name"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength225,
                ]"
                solo
              ></v-text-field>
            </v-col>
            <!-- Date -->
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" md="6" style="margin-top: 30px">
                  <v-radio-group
                    v-model="item.course_date_type"
                    :rules="[validationRules.required]"
                  >
                    <v-row>
                      <v-col cols="6" md="6" sm="6"
                        ><v-radio value="hijri" :label="$t('Hijri')"></v-radio
                      ></v-col>
                      <v-col cols="6" md="6" sm="6"
                        ><v-radio
                          value="gregorian"
                          :label="$t('Gregorian')"
                        ></v-radio
                      ></v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="6" style="">
                  <div>
                    <p class="value">{{ $t("Date") }}</p>
                  </div>
                  <v-text-field
                    :id="'course_date' + index"
                    append-icon="calendar_today"
                    :rules="[validationRules.required]"
                    v-show="item.course_date_type == 'gregorian'"
                    v-model.trim="item.course_date"
                    autocomplete="off"
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>

                  <v-text-field
                    :id="'course_date_hijri' + index"
                    append-icon="calendar_today"
                    :rules="[validationRules.required]"
                    v-show="item.course_date_type == 'hijri'"
                    v-model.trim="item.course_date_hijri"
                    autocomplete="off"
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <!-- Duration -->
            <v-col cols="12" md="4" style="margin-top: 10px">
              <div>
                <p class="value">
                  {{ $t("Duration") }}
                  <span style="color: #888"> ( {{ $t("hours") }} ) </span>
                </p>
              </div>
              <v-text-field
                :rules="[
                  validationRules.maxLength25,
                  validationRules.positiveFloatNumber,
                ]"
                v-model="item.course_duration"
                solo
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- source -->
            <v-col cols="12" md="6">
              <div>
                <p class="value">{{ $t("Source") }}</p>
              </div>
              <v-text-field
                :rules="[
                  validationRules.required,
                  validationRules.maxLength225,
                ]"
                v-model="item.course_source"
                solo
              ></v-text-field>
            </v-col>

            <!-- Grade -->
            <v-col cols="12" md="6">
              <div>
                <p class="value">{{ $t("Grade") }}</p>
              </div>
              <v-text-field
                :rules="[
                  validationRules.required,
                  validationRules.maxLength225,
                ]"
                v-model="item.course_grade"
                solo
              ></v-text-field>
            </v-col>
            <!-- Notes -->
            <v-col cols="12" md="12">
              <div>
                <p class="value">{{ $t("Notes") }}</p>
              </div>
              <v-textarea
                :rules="[validationRules.maxLength400]"
                v-model="item.course_notes"
                solo
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- End Course -->

      <!-- add new course -->
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            class="main-color custom-btn"
            style="background-color: transparent; border: 0; box-shadow: none"
            @click="addCource()"
            ><v-icon> add_circle </v-icon> {{ $t("Add Course") }}</v-btn
          >
        </v-col>
      </v-row>
      <!-- Actions -->
      <v-row>
        <v-col cols="12" md="4" style="text-align: left !important"
          ><v-btn
            class="main-color"
            style="padding: 12px 30px"
            @click="previous()"
            >{{ $t("Previous") }}</v-btn
          ></v-col
        >
        <v-col cols="12" md="8" class="text-right">
          <v-btn
            class="modal-btn-cancel"
            @click="cancel()"
            style="padding: 12px 30px"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            class="modal-btn-cancel"
            style="
              padding: 12px 30px;
              background-color: #7297fe;
              color: #ffffff !important;
            "
            @click="savecourses"
            >{{ $t("Next") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar v-model="snackbar" color="red" :top="true" :right="true">
      {{ text }}
      <v-btn color="red" text @click="snackbar = false">
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
var moment_hijri = require("moment-hijri");
moment.locale("es");

import { validationMixin } from "../../../mixins/validationMixin";

export default {
  mixins: [validationMixin],
  name: "Courses",
  props: ["coursesObject"],
  data() {
    return {
      valid: true,
      color: "red",
      snackbar: false,
      text: "Missing Inputs",
    };
  },
  watch: {
    coursesObject: {
      handler() {
        // console.log("changed");
        this.coursesObject.forEach((item) => {
          if (item.course_date != "") {
            var m = moment_hijri(item.course_date, "DD/MM/YYYY");
            item.course_date_hijri = m.format("iDD/iMM/iYYYY");
          }
          if (item.course_date_hijri != "") {
            var h = moment_hijri(item.course_date_hijri, "iDD/iMM/iYYYY");
            item.course_date = h.format("DD/MM/YYYY");
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    deleteCourse(index) {
      this.coursesObject.splice(index, 1);
    },
    addCource() {
      let coursesObject = {
        course_date_type: "hijri",
        course_name: "",
        course_date: "",
        course_date_hijri: "",
        course_duration: "",
        course_source: "",
        course_grade: "",
        course_notes: "",
      };
      this.coursesObject.push(coursesObject);
      this.activateDatePicker();
    },
    cancel() {
      this.$router.push(
        {
          path: "/employees",
        },
        () => {}
      );
    },
    previous() {
      this.$emit("prevTab", 1);
    },

    savecourses() {
      if (this.valid == false) {
        this.validate();
        this.snackbar = true;
      } else {
        this.$emit("tabIsValid", 2);
      }
    },
    activateDatePicker() {
      let _this = this;

      $(document).ready(function () {
        _this.coursesObject.forEach((element, index) => {
          $("#course_date" + index).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

                if ($(this).attr("id") == "course_date" + index) {
                  _this.coursesObject[index].course_date = dateH;
                }
              } else {
                if ($(this).attr("id") == "course_date" + index) {
                  _this.coursesObject[index].course_date = "";
                }
              }
            },
          });

          $("#course_date_hijri" + index).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            calendar: $.calendars.instance("islamic"),
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                if ($(this).attr("id") == "course_date_hijri" + index) {
                  _this.coursesObject[index].course_date_hijri = dateH;
                }

                // _this.generalInfoObj.date_of_birth_hijri = dateH;
              } else {
                if ($(this).attr("id") == "course_date_hijri" + index) {
                  _this.coursesObject[index].course_date_hijri = "";
                }
              }
            },
          });
        });
      });
    },
  },

  mounted() {
    // this.activateDatePicker();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";
#generalInfo {
  margin-top: 100px;
}

.ui-btn {
  margin: 0 10px 0;
}

label {
  margin-top: 7px !important;
}

.error-txt {
  font-size: 0.875rem !important;
  margin-bottom: 0 !important;
}

@media (max-width: 600px) {
  .info {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>
