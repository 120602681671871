<template>
  <div id="generalInfo" class="">
    <v-form ref="form" v-model="valid" v-on:submit.prevent="saveSalary">
      <!-- Row Code and Name  -->
      <v-row class="form-item">
        <v-col cols="12" md="12">
          <div>
            <p class="value">{{ $t("Basic Salary") }}</p>
          </div>
          <v-text-field
            :rules="[
              validationRules.required,
              validationRules.floatNumberNotZero,
            ]"
            v-model="SalaryObject.basic_salary"
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- ss -->

      <!-- Row Code Alowaces  -->
      <v-row class="form-item">
        <v-col cols="12" md="12">
          <div>
            <p class="value">{{ $t("Allowances") }}</p>
          </div>
          <v-autocomplete
            :items="allowancesItems"
            @change="hideLabel = true && onSelect(SalaryObject.allowaIds)"
            item-text="name"
            item-value="id"
            multiple
            v-model="SalaryObject.allowaIds"
            chips
            :rules="[validationRules.required]"
            solo
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- end Alowaces -->
      <!-- allowancesEmployee v-if="SalaryObject.allowancesEmployee.length > 0" -->
      <div v-if="SalaryObject.allowancesEmployee.length > 0">
        <v-row class="form-item">
          <v-col
            v-for="(allowance, index) in SalaryObject.allowancesEmployee"
            :key="index"
            cols="12"
            md="3"
          >
            <div>
              <span class="allowance-title">{{ allowance.name }}</span>
              <v-row>
                <v-col cols="12" md="7">
                  <v-text-field
                    class="allowance-input"
                    v-if="allowance.account_type == 'amount'"
                    v-model="allowance.value"
                    :rules="[
                      validationRules.required,
                      validationRules.numericNotZero,
                      validationRules.maxLength10,
                    ]"
                  ></v-text-field>
                  <v-text-field
                    class="allowance-input"
                    v-else
                    v-model="allowance.value"
                    :rules="[
                      validationRules.required,
                      validationRules.percentage,
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <span
                    v-if="allowance.account_type == 'amount'"
                    class="type"
                    >{{ $t("SAR") }}</span
                  >
                  <span v-else class="type">%</span>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>

      <!-- Actions -->
      <v-row>
        <v-col cols="12" md="4" style="text-align: left !important"
          ><v-btn
            class="main-color"
            style="padding: 12px 30px"
            @click="previous()"
            >{{ $t("Previous") }}</v-btn
          ></v-col
        >
        <v-col cols="12" md="8" class="text-right">
          <v-btn
            class="modal-btn-cancel"
            @click="cancel()"
            style="padding: 12px 30px"
            >{{ $t("Cancel") }}</v-btn
          >
          <!-- <button class="ui-btn submit" @click="saveSalary()">Next</button> -->
          <v-btn
            class="modal-btn-cancel"
            style="
              padding: 12px 30px;
              background-color: #7297fe;
              color: #ffffff !important;
            "
            @click="saveSalary"
            >{{ $t("Next") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar v-model="snackbar" color="red" :top="true" :right="true">
      {{ text }}
      <v-btn color="red" text @click="snackbar = false">
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import { validationMixin } from "../../../mixins/validationMixin";
// import moment from "moment";
export default {
  mixins: [validationMixin],
  name: "Salary",
  props: ["SalaryObject", "generalInfoObj"],
  data() {
    return {
      valid: true,
      color: "red",
      snackbar: false,
      text: "Missing Inputs",
      ahmed: "asfa",
      allowancesItems: [],
    };
  },
  watch: {},
  methods: {
    onSelect(object) {
      let types = [];
      object.forEach((element) => {
        console.log(element);
        let indexid = this.allowancesItems.findIndex((p) => p.id == element);
        let name = this.allowancesItems[indexid].name;
        let type = this.allowancesItems[indexid].type;
        let value = this.allowancesItems[indexid].value;
        let account_type = this.allowancesItems[indexid].account_type;
        let allowance_id = this.allowancesItems[indexid].id;
        // console.log(type);
        types.push({
          allowance_id: allowance_id,
          name: name,
          type: type,
          value: value,
          account_type: account_type,
        });
      });
      this.SalaryObject.allowancesEmployee = types;
      this.errorAllowancesItems = false;
    },
    getAllowances() {
      axios
        .get(
          this.getApiUrl +
            "/employee/getAllowances?nationality_id=" +
            this.generalInfoObj.nationality_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              //the token is a variable which holds the token
            },
          }
        )
        .then((response) => {
          this.allowancesItems = response.data.data;
          // console.log(response);
        });
    },
    cancel() {
      this.$router.push(
        {
          path: "/employees",
        },
        () => {}
      );
    },
    previous() {
      this.$emit("prevTab", 2);
    },

    saveSalary() {
      if (this.valid == false) {
        this.validate();
        this.snackbar = true;
      } else {
        this.$emit("tabIsValid", 3);
      }
    },
  },

  mounted() {
    this.getAllowances();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";
#generalInfo {
  margin-top: 100px;
}

.allowance-title {
  padding: 10px;
  background: #b9cbff;
  display: block;
  color: #fff;
  text-align: center;
  border-radius: 35px;
}
.allowance-input {
  border-radius: 35px;
}
.type {
  padding: 12px 0;
  display: block;
}
.ui-btn {
  margin: 0 10px 0;
}

label {
  margin-top: 7px !important;
}

.error-txt {
  font-size: 0.875rem !important;
  margin-bottom: 0 !important;
}

@media (max-width: 600px) {
  .info {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>
