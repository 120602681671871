<template>
  <div id="generalInfo" class="">
    <v-form ref="form" v-model="valid" v-on:submit.prevent="saveJob">
      <!-- Row Code and Name  -->
      <v-row class="form-item">
        <v-col cols="12" md="12">
          <div>
            <p class="value">{{ $t("Job Title") }}</p>
          </div>
          <v-autocomplete
            :items="jobs"
            @change="hideLabel = true && select(jobOpject.job_id)"
            item-text="name"
            item-value="id"
            v-model="jobOpject.job_id"
            :rules="[validationRules.required]"
            solo
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- ss -->

      <!-- Row Experience Years Inside -->
      <v-row class="form-item">
        <v-col cols="12" md="4">
          <div>
            <p class="value">{{ $t("Experience Years Inside KSA") }}</p>
          </div>
          <v-text-field
            v-model="jobOpject.experience_years_ksa"
            :rules="[validationRules.required]"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <div>
            <p class="value">{{ $t("Experience Years Outside KSA") }}</p>
          </div>
          <v-text-field
            v-model="jobOpject.experience_years_outside_ksa"
            :rules="[validationRules.required]"
            solo
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- education_level -->
      <v-row class="form-item">
        <v-col class="12" md="12">
          <v-row style="margin-left: -12px">
            <v-col cols="12" md="3" style="margin-top: 15px">
              <div>
                <p class="value">{{ $t("Educational Level") }}</p>
              </div>
              <v-text-field
                v-model="jobOpject.education_level"
                :rules="[validationRules.required]"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" style="margin-top: 15px">
              <div>
                <p class="value">{{ $t("Source") }}</p>
              </div>

              <v-text-field
                v-model="jobOpject.education_level_source"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100,
                ]"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="6" style="margin-top: 30px">
                  <v-radio-group
                    v-model="jobOpject.education_level_date_type"
                    :rules="[validationRules.required]"
                  >
                    <v-row>
                      <v-col cols="6" md="6" sm="6"
                        ><v-radio
                          value="hijri"
                          :label="$i18n.t('Hijri')"
                        ></v-radio
                      ></v-col>
                      <v-col cols="6" md="6" sm="6"
                        ><v-radio
                          value="gregorian"
                          :label="$i18n.t('Gregorian')"
                        ></v-radio
                      ></v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="6" style="">
                  <div>
                    <p class="value">{{ $t("Date") }}</p>
                  </div>
                  <v-text-field
                    id="education_level_date_gregorian"
                    append-icon="calendar_today"
                    :rules="[validationRules.required]"
                    v-show="jobOpject.education_level_date_type == 'gregorian'"
                    autocomplete="off"
                    v-model.trim="jobOpject.education_level_date_gregorian"
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>

                  <v-text-field
                    id="education_level_date_hijri"
                    :rules="[validationRules.required]"
                    append-icon="calendar_today"
                    v-show="jobOpject.education_level_date_type == 'hijri'"
                    autocomplete="off"
                    v-model.lazy="jobOpject.education_level_date_hijri"
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="12" md="12">
          <v-row style="margin-left: -12px">
            <v-col cols="12" md="3" style="margin-top: 15px">
              <div>
                <p class="value">{{ $t("Educational Qualification") }}</p>
              </div>
              <v-text-field
                v-model="jobOpject.education_qualification"
                :rules="[validationRules.required, validationRules.maxLength25]"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3" style="margin-top: 15px">
              <div>
                <p class="value">{{ $t("Source") }}</p>
              </div>
              <v-text-field
                v-model="jobOpject.education_qualification_source"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100,
                ]"
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col cols="12" md="6" style="margin-top: 30px">
                  <v-radio-group
                    v-model="jobOpject.education_qualification_date_type"
                    :rules="[validationRules.required]"
                  >
                    <v-row>
                      <v-col cols="6" md="6" sm="6"
                        ><v-radio
                          value="hijri"
                          :label="$i18n.t('Hijri')"
                        ></v-radio
                      ></v-col>
                      <v-col cols="6" md="6" sm="6"
                        ><v-radio
                          value="gregorian"
                          :label="$i18n.t('Gregorian')"
                        ></v-radio
                      ></v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="6" style="">
                  <div>
                    <p class="value">{{ $t("Date") }}</p>
                  </div>
                  <v-text-field
                    id="education_qualification_date_gregorian"
                    append-icon="calendar_today"
                    :rules="[validationRules.required]"
                    v-show="
                      jobOpject.education_qualification_date_type == 'gregorian'
                    "
                    autocomplete="off"
                    v-model.trim="
                      jobOpject.education_qualification_date_gregorian
                    "
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>

                  <v-text-field
                    id="education_qualification_date_hijri"
                    append-icon="calendar_today"
                    :rules="[validationRules.required]"
                    v-show="
                      jobOpject.education_qualification_date_type == 'hijri'
                    "
                    autocomplete="off"
                    v-model.lazy="jobOpject.education_qualification_date_hijri"
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4" style="text-align: left !important"
          ><v-btn
            class="main-color"
            style="padding: 12px 30px"
            @click="previous()"
            >{{ $t("Previous") }}</v-btn
          ></v-col
        >
        <v-col cols="12" md="8" class="text-right">
          <v-btn
            class="modal-btn-cancel"
            @click="cancel()"
            style="padding: 12px 30px"
            >{{ $t("Cancel") }}</v-btn
          >
          <button class="ui-btn submit" @click="saveJob()">
            {{ $t("Next") }}
          </button>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar v-model="snackbar" color="red" :top="true" :right="true">
      {{ text }}
      <v-btn color="red" text @click="snackbar = false">
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
var moment_hijri = require("moment-hijri");
moment.locale("es");
// import ACL from "../../acl";
import { validationMixin } from "../../../mixins/validationMixin";
// import moment from "moment";
export default {
  mixins: [validationMixin],
  name: "JobDetails",
  props: ["jobOpject", "SalaryObject"],
  data() {
    return {
      valid: true,
      color: "red",
      snackbar: false,
      text: this.$i18n.t("Missing Inputs"),
      jobs: [],
    };
  },
  watch: {
    "jobOpject.education_level_date_gregorian": function (v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.jobOpject.education_level_date_hijri = m.format("iDD/iMM/iYYYY");
      } else {
        this.jobOpject.education_level_date_hijri = "";
      }
    },
    "jobOpject.education_level_date_hijri": function (v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.jobOpject.education_level_date_gregorian = h.format("DD/MM/YYYY");
      } else {
        this.jobOpject.education_level_date_gregorian = "";
      }
    },

    "jobOpject.education_qualification_date_gregorian": function (v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.jobOpject.education_qualification_date_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.jobOpject.education_qualification_date_hijri = "";
      }
    },
    "jobOpject.education_qualification_date_hijri": function (v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.jobOpject.education_qualification_date_gregorian = h.format(
          "DD/MM/YYYY"
        );
      } else {
        this.jobOpject.education_qualification_date_gregorian = "";
      }
    },
  },
  methods: {
    select(id) {
      let indexid = this.jobs.findIndex((p) => p.id == id);
      let valuebaseSalary = this.jobs[indexid].base_salary;
      this.SalaryObject.basic_salary = valuebaseSalary;
      // console.log("select base is=>" + valuebaseSalary);
    },
    cancel() {
      this.$router.push(
        {
          path: "/employees",
        },
        () => {}
      );
    },
    previous() {
      this.$emit("prevTab", 0);
    },
    getJobs() {
      axios
        .get(this.getApiUrl + "/employee/allJobs", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.jobs = response.data.data;
          // console.log(response);
        });
    },
    activateDatePicker() {
      let _this = this;

      $(document).ready(function () {
        $(
          "#education_level_date_gregorian,#education_qualification_date_gregorian"
        ).calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function (date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              if ($(this).attr("id") == "education_level_date_gregorian") {
                _this.jobOpject.education_level_date_gregorian = dateH;
              }
              if (
                $(this).attr("id") == "education_qualification_date_gregorian"
              ) {
                _this.jobOpject.education_qualification_date_gregorian = dateH;
              }
            } else {
              if ($(this).attr("id") == "date_of_birth_gregorian") {
                _this.jobOpject.education_level_date_gregorian = "";
              }
              if (
                $(this).attr("id") == "education_qualification_date_gregorian"
              ) {
                _this.jobOpject.education_qualification_date_gregorian = "";
              }
            }
          },
        });
        $(document).ready(function () {
          $(
            "#education_level_date_hijri,#education_qualification_date_hijri"
          ).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            calendar: $.calendars.instance("islamic"),
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                if ($(this).attr("id") == "education_level_date_hijri") {
                  _this.jobOpject.education_level_date_hijri = dateH;
                }
                if (
                  $(this).attr("id") == "education_qualification_date_hijri"
                ) {
                  _this.jobOpject.education_qualification_date_hijri = dateH;
                }

                // _this.generalInfoObj.date_of_birth_hijri = dateH;
              } else {
                if ($(this).attr("id") == "education_level_date_hijri") {
                  _this.jobOpject.education_level_date_hijri = "";
                }
                if (
                  $(this).attr("id") == "education_qualification_date_hijri"
                ) {
                  _this.jobOpject.education_qualification_date_hijri = "";
                }
              }
            },
          });
        });
      });
    },
    saveJob() {
      if (this.valid == false) {
        this.validate();
        this.snackbar = true;
      } else {
        this.$emit("tabIsValid", 1);
      }
    },
  },

  mounted() {
    this.getJobs();
    this.activateDatePicker();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";
#generalInfo {
  margin-top: 100px;
}

.ui-btn {
  margin: 0 10px 0;
}

label {
  margin-top: 7px !important;
}

.error-txt {
  font-size: 0.875rem !important;
  margin-bottom: 0 !important;
}

@media (max-width: 600px) {
  .info {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>
