<template>
  <div id="cloneAcademic">
    <div class="header">
      <h2 class="main-color">{{ $t("Employee Details") }}</h2>
      <hr />
    </div>
    <div class="prog-content padding-top">
      <ul class="progressbar" style="padding: 0 !important">
        <li :class="{ active: tab >= 0 }" style="width: 20% !important">
          <span class="icon" style="margin: 0 auto !important"
            ><i class="fas fa-info"></i>
          </span>
          <span class="text">{{ $t("General Information") }}</span>
        </li>
        <li
          :class="{ active: tab >= 1 }"
          @click="changeTab(1)"
          style="text-align: rights !important; width: 20% !important"
        >
          <span class="icon">
            <v-icon style="color: #fff"> work </v-icon>
          </span>
          <span class="text">{{ $t("Job Details") }}</span>
        </li>

        <li
          :class="{ active: tab >= 2 }"
          @click="changeTab(2)"
          style="text-align: rights !important; width: 20% !important"
        >
          <span class="icon">
            <v-icon style="color: #fff"> local_library </v-icon>
          </span>
          <span class="text">{{ $t("Courses") }}</span>
        </li>

        <li
          :class="{ active: tab >= 3 }"
          @click="changeTab(3)"
          style="text-align: rights !important; width: 20% !important"
        >
          <span class="icon">
            <v-icon style="color: #fff"> attach_money </v-icon>
          </span>
          <span class="text">{{ $t("Salary") }}</span>
        </li>

        <li
          :class="{ active: tab >= 4 }"
          @click="changeTab(4)"
          style="text-align: rights !important; width: 20% !important"
        >
          <span class="icon">
            <i class="fa fa-file"></i>
          </span>
          <span class="text">{{ $t("Contract") }}</span>
        </li>
      </ul>
    </div>

    <div class="loader text-center" v-if="!loaded">
      <img src="../../../assets/Spinner-1s-200px.gif" alt="" />
    </div>

    <GeneralInfo
      :generalInfoObj="generalInfoObj"
      :validation_errors="validation_errors"
      v-if="tab == 0 && loaded"
      v-on:tabIsValid="makeTabValid"
    ></GeneralInfo>
    <JobDetails
      :jobOpject="jobOpject"
      :SalaryObject="SalaryObject"
      v-if="tab == 1 && loaded"
      v-on:tabIsValid="makeTabValid"
      v-on:prevTab="prev"
    ></JobDetails>

    <Courses
      :coursesObject="coursesObject"
      v-if="tab == 2 && loaded"
      v-on:tabIsValid="makeTabValid"
      v-on:prevTab="prev"
    ></Courses>

    <Salary
      :SalaryObject="SalaryObject"
      :generalInfoObj="generalInfoObj"
      v-if="tab == 3 && loaded"
      v-on:tabIsValid="makeTabValid"
      v-on:prevTab="prev"
    ></Salary>

    <Contract
      :ContractObject="ContractObject"
      v-if="tab == 4 && loaded"
      v-on:tabIsValid="makeTabValid"
      v-on:prevTab="prev"
      :loading="loading"
    ></Contract>

    <v-snackbar v-model="snackbar" :color="color" :top="true" :right="true">
      {{ text }}
      <v-btn color="red" text @click="snackbar = false">
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
// import axios from "axios";
import axios from "axios";
import GeneralInfo from "../../../components/hr/Employee/GeneralInformation";
import JobDetails from "../../../components/hr/Employee/JobDetails";
import Courses from "../../../components/hr/Employee/courses";
import Salary from "../../../components/hr/Employee/Salary";
import Contract from "../../../components/hr/Employee/Contract";

export default {
  name: "CreateEmployees",
  components: {
    GeneralInfo,
    JobDetails,
    Courses,
    Salary,
    Contract,
  },
  data() {
    return {
      loading: false,
      tab: 0,
      loaded: true,
      color: "red",
      snackbar: false,
      text: "asfa",
      validTab: [false, false, false, false, false],
      generalInfoObj: {
        date_of_birth_type: "hijri",
        sponsor_expiry_date_type: "hijri",
        citizen_id_expiry_date_type: "hijri",
        citizen_passport_expiry_date_type: "hijri",
        code: "",
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        email: "",
        mobile_country_code_id: 187,
        mobile: "",
        date_of_birth_gregorian: "",
        date_of_birth_hijri: "",
        nationality_id: "",
        place_of_birth: "",
        gendar: "",
        sponsor_name: "",
        sponsor_relationship: "",
        sponsor_job: "",
        sponsor_id_number: "",
        sponsor_source: "",
        sponsor_expiry_date_gregorian: "",
        sponsor_expiry_date_hijri: "",
        sponsor_notes: "",
        address_country_id: "",
        address_city: "",
        address_street: "",
        address_building_number: "",
        address_additional_number: "",
        address_zip_code: "",
        citizen_id_number: "",
        citizen_id_source: "",
        citizen_id_expiry_date_gregorian: "",
        citizen_id_expiry_date_hijri: "",
        citizen_passport_number: "",
        citizen_passport_source: "",
        citizen_passport_expiry_date_gregorian: "",
        citizen_passport_expiry_date_hijri: "",
        citizen_social_insurance_number: "",
      },
      jobOpject: {
        education_level_date_type: "hijri",
        education_qualification_date_type: "hijri",
        job_id: "",
        experience_years_ksa: "",
        experience_years_outside_ksa: "",
        education_level: "",
        education_level_source: "",
        education_level_date_gregorian: "",
        education_level_date_hijri: "",
        education_qualification: "",
        education_qualification_source: "",
        education_qualification_date_gregorian: "",
        education_qualification_date_hijri: "",
      },
      coursesObject: [],
      SalaryObject: {
        basic_salary: "",
        net_salary: "",
        allowaIds: [],
        allowancesEmployee: [],
      },
      ContractObject: {
        contract_date_signed_type: "hijri",
        contract_start_date_type: "hijri",
        contract_end_date_type: "hijri",
        contract_probationdate_end_data_type: "hijri",
        contract_date_signed: "",
        contract_date_signed_hijri: "",
        shift_id: "",
        contract_start_date: "",
        contract_start_date_hijri: "",
        contract_end_date: "",
        contract_end_date_hijri: "",
        duration: "",
        contract_probationdate_end_data: "",
        contract_probationdate_end_data_hijri: "",
        allow_mobile_check_in: "",
      },
      validation_errors: {
        code: "",
      },
    };
  },
  methods: {
    changeTab(tab) {
      if (tab == 0) {
        this.tab = tab;
      } else {
        if (this.validTab[tab - 1]) {
          this.tab = tab;
        }
      }
    },
    prev(tab) {
      // this.changeTab(tab);
      this.tab = tab;
    },
    makeTabValid(tab) {
      if (tab < 4) {
        this.validTab[tab] = true;

        this.changeTab(tab + 1);
      } else {
        // console.log("tab" + tab);
        const data = Object.assign(
          this.generalInfoObj,
          this.jobOpject,
          this.ContractObject
        );
        data.courses = this.coursesObject;
        data.allowances = this.SalaryObject;
        this.loading = true;
        axios
          .post(this.getApiUrl + "/employee/store", data, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == true) {
              Object.assign(
                this.validation_errors,
                response.data.status.validation_errors
              );
              this.snackbar = true;
              this.text = response.data.status.validation_errors.code[0];
              this.tab = 0;
            } else {
              // this.changeTab(1);
              this.$router.push(
                {
                  path: "/employees/show/" + response.data.data.id,
                },
                () => {}
              );
            }
          });
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";

.prog-content {
  width: 100%;
  z-index: 1;
}

.header h2 {
  font-size: 1.5rem;
}

.progressbar {
  li {
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
  }

  li .icon {
    width: 46px;
    height: 46px;
    line-height: 46px;
    border: 1px solid $main-gray;
    display: block;
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
    background-color: $main-gray;
    color: #ffffff;
    position: relative;
    z-index: 11;
  }

  li .text {
    margin-top: 10px;
    display: inline-block;
  }

  li::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $main-gray;
    top: 23px;
    left: -50%;
    z-index: 1;
  }
  li:first-child:after {
    content: none;
  }
  li.active::after {
    background-color: $main-color;
  }

  li.active {
    color: $main-color;
  }
  li.active .icon {
    border: 1px solid $main-color;
    background-color: $main-color;
    color: #ffffff;
  }
}

@media (max-width: 600px) {
  .progressbar li .icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  li .text {
    display: none !important;
  }

  li.active .text {
    display: inline-block !important;
    font-size: 10px !important;
  }
  .loader img {
    margin-top: 50px;
    display: inline-block;
    margin-right: 2rem;
  }
}

.subject-icon {
  max-width: 30px !important;
}
</style>
