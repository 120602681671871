<template>
  <div id="generalInfo" class="" v-if="generalInfoObj">
    <v-form
      ref="form"
      v-model="valid"
      v-on:submit.prevent="saveGenralInformation"
    >
      <!-- Row Code and Name  -->
      <v-row class="form-item">
        <v-col cols="12" md="4">
          <div>
            <p class="value">{{ $t("Image") }}</p>
          </div>
          <v-file-input @change="readFiles" chips solo> </v-file-input>
          <p class="mb-0 error--text" v-if="imageValidation">
            {{ imageValidation }}
          </p>
          <v-text-field
            v-model="generalInfoObj.image"
            v-show="false"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <div>
            <p class="value">{{ $t("Full Name In English") }}</p>
          </div>
          <v-text-field
            v-model="generalInfoObj.en.name"
            :rules="[validationRules.required, validationRules.maxLength400]"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <div>
            <p class="value">{{ $t("Full Name In Arabic") }}</p>
          </div>
          <v-text-field
            v-model="generalInfoObj.ar.name"
            :rules="[
              validationRules.required,
              validationRules.maxLength400,
              CheckArabicCharactersOnly(generalInfoObj.ar.name),
            ]"
            solo
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- code -->
      <v-row class="form-item">
        <v-col cols="12" md="6">
          <div>
            <p class="value">{{ $t("Code") }}</p>
          </div>
          <v-text-field
            :rules="[validationRules.required]"
            v-model="generalInfoObj.code"
            solo
          ></v-text-field>

          <div class="error-sec" v-if="validation_errors.code">
            <p class="error-txt red--text">{{ validation_errors.code }}</p>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <!-- Row Date of birth  -->
          <v-row class="align-items-center">
            <v-col cols="12" md="6" class="py-0">
              <v-radio-group
                v-model="generalInfoObj.date_of_birth_type"
                :rules="[validationRules.required]"
              >
                <v-row>
                  <v-col cols="6" md="6" sm="6" class="py-0"
                    ><v-radio value="hijri" :label="$t('Hijri')"></v-radio
                  ></v-col>
                  <v-col cols="6" md="6" sm="6" class="py-0"
                    ><v-radio
                      value="gregorian"
                      :label="$t('Gregorian')"
                    ></v-radio
                  ></v-col>
                </v-row>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6" style="">
              <div>
                <p class="value">{{ $t("Date Of Birth") }}</p>
              </div>
              <v-text-field
                :rules="[validationRules.required]"
                v-show="generalInfoObj.date_of_birth_type == 'gregorian'"
                autocomplete="off"
                append-icon="calendar_today"
                id="date_of_birth_gregorian"
                v-model.trim="generalInfoObj.date_of_birth_gregorian"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="date_of_birth_hijri"
                :rules="[validationRules.required]"
                v-show="generalInfoObj.date_of_birth_type == 'hijri'"
                append-icon="calendar_today"
                autocomplete="off"
                v-model.lazy="generalInfoObj.date_of_birth_hijri"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Row Mobile and Email -->
      <v-row class="form-item">
        <v-col cols="12" md="6">
          <div>
            <p class="value">{{ $t("Email") }}</p>
          </div>
          <v-text-field
            :rules="[
              validationRules.required,
              validationRules.maxLength150,
              validationRules.email,
            ]"
            v-model="generalInfoObj.email"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-row style="margin-left: -12px">
            <v-col cols="5" sm="5" md="4" class="mobile-code pr-0">
              <!-- <div id="country-code"> -->
              <label>{{ $t("Code") }} </label>
              <v-autocomplete
                :items="codes"
                :rules="[validationRules.required]"
                required
                @change="hideLabel = true"
                item-text="title"
                item-value="value"
                v-model="generalInfoObj.mobile_country_code_id"
                solo
              >
                <template slot="selection" slot-scope="data">
                  <v-flex xs3>
                    <v-avatar size="25">
                      <img :src="data.item.icon" />
                    </v-avatar>
                  </v-flex>
                  <v-flex class="ml-3">{{ data.item.title }}</v-flex>
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-avatar
                    style="width: 20px; min-width: 20px; height: 20px"
                  >
                    <img :src="data.item.icon" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.title"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="7" sm="7" md="8">
              <label>{{ $t("Mobile") }}</label>

              <v-text-field
                :rules="[
                  validationRules.required,
                  mobileRule(
                    generalInfoObj.mobile_country_code_id,
                    generalInfoObj.mobile
                  ),
                ]"
                v-model.trim="generalInfoObj.mobile"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Row Natonalisy and gendar -->
      <v-row class="form-item">
        <v-col cols="12" md="3">
          <div>
            <p class="value">{{ $t("Nationality") }}</p>
          </div>
          <v-autocomplete
            :items="nationality"
            @change="hideLabel = true"
            :rules="[validationRules.required]"
            item-text="name"
            item-value="id"
            v-model="generalInfoObj.nationality_id"
            solo
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <div>
            <p class="value">{{ $t("Place Of Birth") }}</p>
          </div>
          <v-text-field
            v-model.trim="generalInfoObj.place_of_birth"
            :rules="[validationRules.required, validationRules.maxLength100]"
            solo
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3" style="margin-top: -10px">
          <v-radio-group
            v-model="generalInfoObj.gendar"
            :rules="[validationRules.required]"
          >
            <div>
              <p class="value text-right_in_rtl">{{ $t("Gender") }}</p>
            </div>
            <v-row>
              <v-col cols="6" md="6" sm="6"
                ><v-radio value="male" :label="$i18n.t('Male')"></v-radio
              ></v-col>
              <v-col cols="6" md="6" sm="6"
                ><v-radio value="female" :label="$i18n.t('Female')"></v-radio
              ></v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>

      <!-- Row Sponsor Detail -->
      <v-row class="form-item">
        <v-col cols="12" md="12"
          ><h4 class="main-color">{{ $t("Sponsor Details") }}</h4></v-col
        >
        <v-col cols="12" md="12">
          <v-row style="margin-left: -12px">
            <v-col cols="12" md="4">
              <div>
                <p class="value">{{ $t("Sponsor Name") }}</p>
              </div>
              <v-text-field
                v-if="generalInfoObj.nationality_id == 187"
                disabled
                solo
              ></v-text-field>

              <v-text-field
                v-else
                :rules="[
                  validationRules.required,
                  validationRules.maxLength400,
                ]"
                v-model="generalInfoObj.sponsor_name"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <div>
                <p class="value">{{ $t("Relationship") }}</p>
              </div>

              <v-autocomplete
                v-if="generalInfoObj.nationality_id == 187"
                disabled
                solo
              >
              </v-autocomplete>
              <v-autocomplete
                v-else
                :items="[
                  $i18n.t('Brother'),
                  $i18n.t('Father'),
                  $i18n.t('Husband'),
                  $i18n.t('Other'),
                ]"
                @change="hideLabel = true"
                v-model="generalInfoObj.sponsor_relationship"
                :rules="[validationRules.required]"
                solo
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <div>
                <p class="value">{{ $t("Job") }}</p>
              </div>
              <v-text-field
                v-if="
                  generalInfoObj.sponsor_relationship == 'Other' ||
                    generalInfoObj.nationality_id == 187
                "
                disabled
                solo
              ></v-text-field>

              <v-text-field
                v-else
                v-model="generalInfoObj.sponsor_job"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100,
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row style="margin-left: -12px">
            <v-col cols="12" md="3">
              <v-row>
                <v-col cols="12" md="12">
                  <div>
                    <p class="value">{{ $t("Sponsor ID Number") }}</p>
                  </div>
                  <v-text-field
                    v-if="
                      generalInfoObj.sponsor_relationship == 'Other' ||
                        generalInfoObj.nationality_id == 187
                    "
                    disabled
                    solo
                  ></v-text-field>

                  <v-text-field
                    v-model="generalInfoObj.sponsor_id_number"
                    v-else
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength25,
                    ]"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="3">
              <v-row>
                <v-col cols="12" md="12">
                  <div>
                    <p class="value">{{ $t("Source") }}</p>
                  </div>
                  <v-text-field
                    v-model="generalInfoObj.sponsor_source"
                    v-if="
                      generalInfoObj.sponsor_relationship == 'Other' ||
                        generalInfoObj.nationality_id == 187
                    "
                    disabled
                    solo
                  ></v-text-field>
                  <v-text-field
                    v-model="generalInfoObj.sponsor_source"
                    v-else
                    :rules="[
                      validationRules.required,
                      validationRules.maxLength25,
                    ]"
                    solo
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row class="align-items-center">
                <v-col cols="12" md="6">
                  <v-radio-group
                    v-model="generalInfoObj.sponsor_expiry_date_type"
                    :rules="[
                      CheckIfInputRequired(
                        generalInfoObj.sponsor_expiry_date_type
                      ),
                    ]"
                  >
                    <v-row>
                      <v-col cols="6" md="6" sm="6" class="py-0"
                        ><v-radio
                          value="hijri"
                          :disabled="
                            generalInfoObj.sponsor_relationship == 'Other' ||
                              generalInfoObj.nationality_id == 187
                          "
                          :label="$i18n.t('Hijri')"
                        ></v-radio
                      ></v-col>
                      <v-col cols="6" md="6" sm="6" class="py-0"
                        ><v-radio
                          value="gregorian"
                          :disabled="
                            generalInfoObj.sponsor_relationship == 'Other' ||
                              generalInfoObj.nationality_id == 187
                          "
                          :label="$i18n.t('Gregorian')"
                        ></v-radio
                      ></v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="6" style="">
                  <div>
                    <p class="value">{{ $t("Expiry Date") }}</p>
                  </div>

                  <v-text-field
                    v-if="
                      generalInfoObj.sponsor_relationship == 'Other' ||
                        generalInfoObj.nationality_id == 187
                    "
                    append-icon="calendar_today"
                    v-show="
                      generalInfoObj.sponsor_expiry_date_type == 'gregorian'
                    "
                    disabled
                    solo
                  ></v-text-field>

                  <v-text-field
                    v-else
                    id="sponsor_expiry_date_gregorian"
                    :rules="[validationRules.required]"
                    append-icon="calendar_today"
                    v-show="
                      generalInfoObj.sponsor_expiry_date_type == 'gregorian'
                    "
                    autocomplete="off"
                    v-model.trim="generalInfoObj.sponsor_expiry_date_gregorian"
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>

                  <v-text-field
                    v-if="
                      generalInfoObj.sponsor_relationship == 'Other' ||
                        generalInfoObj.nationality_id == 187
                    "
                    append-icon="calendar_today"
                    v-show="generalInfoObj.sponsor_expiry_date_type == 'hijri'"
                    disabled
                    solo
                  ></v-text-field>

                  <v-text-field
                    id="sponsor_expiry_date_hijri"
                    v-else
                    :rules="[validationRules.required]"
                    v-show="generalInfoObj.sponsor_expiry_date_type == 'hijri'"
                    append-icon="calendar_today"
                    autocomplete="off"
                    v-model.lazy="generalInfoObj.sponsor_expiry_date_hijri"
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <div>
            <p class="value">{{ $t("Notes") }}</p>
          </div>
          <v-text-field
            v-model="generalInfoObj.sponsor_notes"
            :disabled="generalInfoObj.nationality_id == 187"
            solo
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Row Address -->
      <v-row class="form-item">
        <v-col cols="12" md="12"
          ><h4 class="main-color">{{ $t("Address details") }}</h4></v-col
        >
        <v-col cols="12" md="12">
          <v-row style="margin-left: -12px">
            <v-col cols="12" md="4">
              <div>
                <p class="value">{{ $t("Country") }}</p>
              </div>

              <v-autocomplete
                :items="nationality"
                :rules="[validationRules.required]"
                @change="hideLabel = true"
                item-text="name"
                item-value="id"
                v-model="generalInfoObj.address_country_id"
                solo
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" md="4">
              <div>
                <p class="value">{{ $t("City") }}</p>
              </div>
              <v-text-field
                v-model="generalInfoObj.address_city"
                :rules="[validationRules.required, validationRules.maxLength50]"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <div>
                <p class="value">{{ $t("Street") }}</p>
              </div>
              <v-text-field
                v-model="generalInfoObj.address_street"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength150,
                ]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <v-row style="margin-left: -12px">
            <v-col cols="12" md="4">
              <div>
                <p class="value">{{ $t("Building Number") }}</p>
              </div>
              <v-text-field
                v-model="generalInfoObj.address_building_number"
                :rules="[validationRules.required, validationRules.maxLength20]"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <div>
                <p class="value">{{ $t("Additional Number") }}</p>
              </div>
              <v-text-field
                v-model="generalInfoObj.address_additional_number"
                :rules="[validationRules.required, validationRules.maxLength20]"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <div>
                <p class="value">{{ $t("Zip Code") }}</p>
              </div>
              <v-text-field
                v-model="generalInfoObj.address_zip_code"
                :rules="[validationRules.required, validationRules.maxLength10]"
                solo
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Citizen Information -->
      <v-row class="form-item">
        <v-col cols="12" md="12"
          ><h4 class="main-color">{{ $t("Citizen Information") }}</h4></v-col
        >
        <v-col class="12" md="12">
          <v-row style="margin-left: -12px">
            <v-col cols="12" md="3">
              <div>
                <p class="value">{{ $t("ID number") }}</p>
              </div>
              <v-text-field
                v-model="generalInfoObj.citizen_id_number"
                :rules="[validationRules.required, validationRules.maxLength25]"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <div>
                <p class="value">{{ $t("Source") }}</p>
              </div>

              <v-text-field
                v-model="generalInfoObj.citizen_id_source"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100,
                ]"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-row class="align-items-center">
                <v-col cols="12" md="6" class="py-0">
                  <v-radio-group
                    v-model="generalInfoObj.citizen_id_expiry_date_type"
                    :rules="[validationRules.required]"
                  >
                    <v-row>
                      <v-col cols="6" md="6" sm="6" class="py-0"
                        ><v-radio value="hijri" :label="$t('Hijri')"></v-radio
                      ></v-col>
                      <v-col cols="6" md="6" sm="6" class="py-0"
                        ><v-radio
                          value="gregorian"
                          :label="$t('Gregorian')"
                        ></v-radio
                      ></v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="6" style="">
                  <div>
                    <p class="value">{{ $t("Expiry Date") }}</p>
                  </div>
                  <v-text-field
                    id="citizen_id_expiry_date_gregorian"
                    append-icon="calendar_today"
                    :rules="[validationRules.required]"
                    v-show="
                      generalInfoObj.citizen_id_expiry_date_type == 'gregorian'
                    "
                    autocomplete="off"
                    v-model.trim="
                      generalInfoObj.citizen_id_expiry_date_gregorian
                    "
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>

                  <v-text-field
                    id="citizen_id_expiry_date_hijri"
                    :rules="[validationRules.required]"
                    append-icon="calendar_today"
                    v-show="
                      generalInfoObj.citizen_id_expiry_date_type == 'hijri'
                    "
                    autocomplete="off"
                    v-model.lazy="generalInfoObj.citizen_id_expiry_date_hijri"
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="12" md="12">
          <v-row style="margin-left: -12px">
            <v-col cols="12" md="3">
              <div>
                <p class="value">{{ $t("Passport Number") }}</p>
              </div>
              <v-text-field
                v-model="generalInfoObj.citizen_passport_number"
                :rules="[validationRules.required, validationRules.maxLength25]"
                solo
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <div>
                <p class="value">{{ $t("Source") }}</p>
              </div>
              <v-text-field
                v-model="generalInfoObj.citizen_passport_source"
                :rules="[
                  validationRules.required,
                  validationRules.maxLength100,
                ]"
                solo
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-row class="align-items-center">
                <v-col cols="12" md="6" class="py-0">
                  <v-radio-group
                    v-model="generalInfoObj.citizen_passport_expiry_date_type"
                    :rules="[validationRules.required]"
                  >
                    <v-row>
                      <v-col cols="6" md="6" sm="6" class="py-0"
                        ><v-radio value="hijri" :label="$t('Hijri')"></v-radio
                      ></v-col>
                      <v-col cols="6" md="6" sm="6" class="py-0"
                        ><v-radio
                          value="gregorian"
                          :label="$t('Gregorian')"
                        ></v-radio
                      ></v-col>
                    </v-row>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="6" style="">
                  <div>
                    <p class="value">{{ $t("Expiry Date") }}</p>
                  </div>
                  <v-text-field
                    id="citizen_passport_expiry_date_gregorian"
                    append-icon="calendar_today"
                    :rules="[validationRules.required]"
                    v-show="
                      generalInfoObj.citizen_passport_expiry_date_type ==
                        'gregorian'
                    "
                    autocomplete="off"
                    v-model.trim="
                      generalInfoObj.citizen_passport_expiry_date_gregorian
                    "
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>

                  <v-text-field
                    id="citizen_passport_expiry_date_hijri"
                    append-icon="calendar_today"
                    :rules="[validationRules.required]"
                    v-show="
                      generalInfoObj.citizen_passport_expiry_date_type ==
                        'hijri'
                    "
                    autocomplete="off"
                    v-model.lazy="
                      generalInfoObj.citizen_passport_expiry_date_hijri
                    "
                    solo
                    @keydown.prevent
                    @paste.prevent
                    @drop.prevent
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <p class="value">{{ $t("Social insurance number") }}</p>
          </div>
          <v-text-field
            v-model="generalInfoObj.citizen_social_insurance_number"
            :rules="[validationRules.maxLength25]"
            solo
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12" class="text-right">
          <v-btn
            class="modal-btn-cancel"
            @click="cancel()"
            style="padding: 12px 30px"
            large
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            class="ui-btn submit"
            @click="saveGenralInformation()"
            :loading="loading"
            :disabled="loading"
            large
          >
            {{ $t("Next") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar v-model="snackbar" color="red" :top="true" :right="true">
      {{ text }}
      <v-btn color="red" text @click="snackbar = false">
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
var moment_hijri = require("moment-hijri");
moment.locale("es");
// import ACL from "../../acl";
import { validationMixin } from "../../../mixins/validationMixin";
// import moment from "moment";
export default {
  mixins: [validationMixin],
  name: "GeneralInfo",
  props: ["generalInfoObj", "validation_errors"],
  data() {
    return {
      loading: false,
      imageValidation: "",
      valid: true,
      codes: [],
      nationality: [],
      color: "red",
      snackbar: false,
      text: this.$i18n.t("Missing Inputs"),
    };
  },
  watch: {
    "generalInfoObj.date_of_birth_gregorian": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.generalInfoObj.date_of_birth_hijri = m.format("iDD/iMM/iYYYY");
      } else {
        this.generalInfoObj.date_of_birth_hijri = "";
      }
    },
    "generalInfoObj.date_of_birth_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.generalInfoObj.date_of_birth_gregorian = h.format("DD/MM/YYYY");
      } else {
        this.generalInfoObj.date_of_birth_gregorian = "";
      }
    },

    "generalInfoObj.sponsor_expiry_date_gregorian": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.generalInfoObj.sponsor_expiry_date_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.generalInfoObj.sponsor_expiry_date_hijri = "";
      }
    },
    "generalInfoObj.sponsor_expiry_date_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.generalInfoObj.sponsor_expiry_date_gregorian = h.format(
          "DD/MM/YYYY"
        );
      } else {
        this.generalInfoObj.sponsor_expiry_date_gregorian = "";
      }
    },

    "generalInfoObj.citizen_id_expiry_date_gregorian": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.generalInfoObj.citizen_id_expiry_date_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.generalInfoObj.citizen_id_expiry_date_hijri = "";
      }
    },
    "generalInfoObj.citizen_id_expiry_date_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.generalInfoObj.citizen_id_expiry_date_gregorian = h.format(
          "DD/MM/YYYY"
        );
      } else {
        this.generalInfoObj.citizen_id_expiry_date_gregorian = "";
      }
    },

    "generalInfoObj.citizen_passport_expiry_date_gregorian": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.generalInfoObj.citizen_passport_expiry_date_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.generalInfoObj.citizen_passport_expiry_date_hijri = "";
      }
    },
    "generalInfoObj.citizen_passport_expiry_date_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.generalInfoObj.citizen_passport_expiry_date_gregorian = h.format(
          "DD/MM/YYYY"
        );
      } else {
        this.generalInfoObj.citizen_passport_expiry_date_gregorian = "";
      }
    },
  },
  methods: {
    checkFileType(event) {
      if (
        event.type == "image/jpeg" ||
        event.type == "image/gif" ||
        event.type == "image/jpg" ||
        event.type == "image/tiff" ||
        event.type == "image/png"
      ) {
        return true;
      } else {
        return (this.imageValidation = "please enter a valid format");
      }
    },
    checkFileSize(event) {
      if (event.size >= 52428800) {
        this.imageValidation = "the image size shouldn't exceed 50M";
      } else {
        return true;
      }
    },
    readFiles(event) {
      this.imageValidation = "";
      console.log(event);
      if (event == null) {
        this.generalInfoObj.image = "";
      } else {
        if (
          this.checkFileType(event) === true &&
          this.checkFileSize(event) === true
        ) {
          const formData = new FormData();
          formData.append("image", event);
          axios
            .post(this.getApiUrl + "/uploadFormImage", formData, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            })
            .then((response) => {
              if (response.data.status.error == false) {
                this.generalInfoObj.image = response.data.data.url;
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
      }
    },
    getCountries() {
      axios.get(this.getApiUrl + "/getCountries").then((response) => {
        this.nationality = response.data.data;
      });
    },
    CheckIfInputRequired(v) {
      // console.log(this.generalInfoObj.sponsor_relationship);
      if (
        this.generalInfoObj.sponsor_relationship != "Other" ||
        this.generalInfoObj.sponsor_relationship != ""
      ) {
        if (v.length <= 0) {
          // console.log("khlkhk");
          return this.$i18n.t("This field is required");
        } else return true;
      }
    },
    getCodeEmop() {
      axios
        .get(this.getApiUrl + "/employee/getEmpCode", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.generalInfoObj.code = response.data.data;
          console.log(response.data.data);
        });
    },
    activateDatePicker() {
      let _this = this;

      $(document).ready(function() {
        $(
          "#date_of_birth_gregorian,#sponsor_expiry_date_gregorian,#citizen_id_expiry_date_gregorian,#citizen_passport_expiry_date_gregorian"
        ).calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              if ($(this).attr("id") == "date_of_birth_gregorian") {
                _this.generalInfoObj.date_of_birth_gregorian = dateH;
              }
              if ($(this).attr("id") == "sponsor_expiry_date_gregorian") {
                _this.generalInfoObj.sponsor_expiry_date_gregorian = dateH;
              }
              if ($(this).attr("id") == "citizen_id_expiry_date_gregorian") {
                _this.generalInfoObj.citizen_id_expiry_date_gregorian = dateH;
              }
              if (
                $(this).attr("id") == "citizen_passport_expiry_date_gregorian"
              ) {
                _this.generalInfoObj.citizen_passport_expiry_date_gregorian = dateH;
              }
            } else {
              if ($(this).attr("id") == "date_of_birth_gregorian") {
                _this.generalInfoObj.date_of_birth_gregorian = "";
              }
              if ($(this).attr("id") == "sponsor_expiry_date_gregorian") {
                _this.generalInfoObj.sponsor_expiry_date_gregorian = "";
              }
              if ($(this).attr("id") == "citizen_id_expiry_date_gregorian") {
                _this.generalInfoObj.citizen_id_expiry_date_gregorian = "";
              }
              if (
                $(this).attr("id") == "citizen_passport_expiry_date_gregorian"
              ) {
                _this.generalInfoObj.citizen_passport_expiry_date_gregorian =
                  "";
              }
            }
          },
        });
        $(document).ready(function() {
          $(
            "#date_of_birth_hijri,#sponsor_expiry_date_hijri,#citizen_id_expiry_date_hijri,#citizen_passport_expiry_date_hijri"
          ).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            calendar: $.calendars.instance("islamic"),
            onSelect: function(date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                if ($(this).attr("id") == "date_of_birth_hijri") {
                  _this.generalInfoObj.date_of_birth_hijri = dateH;
                }
                if ($(this).attr("id") == "sponsor_expiry_date_hijri") {
                  _this.generalInfoObj.sponsor_expiry_date_hijri = dateH;
                }
                if ($(this).attr("id") == "citizen_id_expiry_date_hijri") {
                  _this.generalInfoObj.citizen_id_expiry_date_hijri = dateH;
                }
                if (
                  $(this).attr("id") == "citizen_passport_expiry_date_hijri"
                ) {
                  _this.generalInfoObj.citizen_passport_expiry_date_hijri = dateH;
                }
                // _this.generalInfoObj.date_of_birth_hijri = dateH;
              } else {
                if ($(this).attr("id") == "date_of_birth_hijri") {
                  _this.generalInfoObj.date_of_birth_hijri = "";
                }
                if ($(this).attr("id") == "sponsor_expiry_date_hijri") {
                  _this.generalInfoObj.sponsor_expiry_date_hijri = "";
                }
                if ($(this).attr("id") == "citizen_id_expiry_date_hijri") {
                  _this.generalInfoObj.citizen_id_expiry_date_hijri = "";
                }
                if (
                  $(this).attr("id") == "citizen_passport_expiry_date_hijri"
                ) {
                  _this.generalInfoObj.citizen_passport_expiry_date_hijri = "";
                }
              }
            },
          });
        });
      });
    },
    saveGenralInformation() {
      if (
        this.generalInfoObj.nationality_id == 187 ||
        this.generalInfoObj.nationality_id === 187
      ) {
        this.generalInfoObj.sponsor_name = "";
        this.generalInfoObj.sponsor_relationship = "";
        this.generalInfoObj.sponsor_job = "";
        this.generalInfoObj.sponsor_id_numbe = "";
        this.generalInfoObj.sponsor_source = "";
        // this.generalInfoObj.sponsor_expiry_date_type = "";
        this.generalInfoObj.sponsor_expiry_date_gregorian = "";
        this.generalInfoObj.sponsor_expiry_date_hijri = "";
        this.generalInfoObj.sponsor_notes = "";
      }
      if (this.valid == false || this.imageValidation != "") {
        this.validate();
        this.snackbar = true;
      } else {
        this.$emit("tabIsValid", 0);
      }
    },
    cancel() {
      this.$router.push(
        {
          path: "/employees",
        },
        () => {}
      );
    },
  },

  mounted() {
    axios.get(this.getApiUrl + "/getCodes").then((response) => {
      this.codes = response.data.data;
    });
    this.getCodeEmop();
    this.getCountries();
    this.activateDatePicker();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";
#generalInfo {
  margin-top: 100px;
}

.ui-btn {
  margin: 0 10px 0;
}

label {
  margin-top: 7px !important;
}

.error-txt {
  font-size: 0.875rem !important;
  margin-bottom: 0 !important;
}

@media (max-width: 600px) {
  .info {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.mobile-code .v-input__slot {
  padding: 0 5px !important;
}
</style>
