<template>
  <div id="generalInfo" class="">
    <v-form ref="form" v-model="valid" v-on:submit.prevent="saveContract">
      <!-- Row Code and Name  -->
      <v-row class="form-item">
        <!-- Date -->
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" md="6" style="margin-top: 30px">
              <v-radio-group
                v-model="ContractObject.contract_date_signed_type"
                :rules="[validationRules.required]"
              >
                <v-row>
                  <v-col cols="6" md="6" sm="6"
                    ><v-radio value="hijri" :label="$t('Hijri')"></v-radio
                  ></v-col>
                  <v-col cols="6" md="6" sm="6"
                    ><v-radio
                      value="gregorian"
                      :label="$t('Gregorian')"
                    ></v-radio
                  ></v-col>
                </v-row>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6" style="">
              <div>
                <p class="value">{{ $t("Date Signed") }}</p>
              </div>
              <v-text-field
                id="contract_date_signed"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="ContractObject.contract_date_signed_type == 'gregorian'"
                v-model.trim="ContractObject.contract_date_signed"
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="contract_date_signed_hijri"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="ContractObject.contract_date_signed_type == 'hijri'"
                v-model.trim="ContractObject.contract_date_signed_hijri"
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <!-- shifts -->
        <v-col cols="12" md="4" style="margin-top: 10px">
          <div>
            <p class="value">{{ $t("Shift") }}</p>
          </div>
          <v-autocomplete
            :items="shifts"
            @change="hideLabel = true"
            item-text="name"
            item-value="id"
            v-model="ContractObject.shift_id"
            :rules="[validationRules.required]"
            solo
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- s -->
      <!-- Start Date and end contract date -->
      <v-row class="form-item">
        <!-- Start Date -->
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" md="6" style="margin-top: 30px">
              <v-radio-group
                v-model="ContractObject.contract_start_date_type"
                :rules="[validationRules.required]"
              >
                <v-row>
                  <v-col cols="6" md="6" sm="6"
                    ><v-radio value="hijri" :label="$i18n.t('Hijri')"></v-radio
                  ></v-col>
                  <v-col cols="6" md="6" sm="6"
                    ><v-radio
                      value="gregorian"
                      :label="$i18n.t('Gregorian')"
                    ></v-radio
                  ></v-col>
                </v-row>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6" style="">
              <div>
                <p class="value">{{ $t("Start Date") }}</p>
              </div>
              <v-text-field
                id="contract_start_date"
                append-icon="calendar_today"
                :rules="[
                  validationRules.required,
                  startDateRule(
                    ContractObject.contract_start_date,
                    ContractObject.contract_probationdate_end_data,
                    $i18n.t('Start date should be less than propation end date')
                  ),
                ]"
                v-show="ContractObject.contract_start_date_type == 'gregorian'"
                v-model.trim="ContractObject.contract_start_date"
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="contract_start_date_hijri"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="ContractObject.contract_start_date_type == 'hijri'"
                v-model.trim="ContractObject.contract_start_date_hijri"
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <!-- End Contract Date -->
        <v-col cols="12" md="6">
          <v-row>
            <v-col cols="12" md="6" style="margin-top: 30px">
              <v-radio-group
                v-model="ContractObject.contract_end_date_type"
                :rules="[validationRules.required]"
              >
                <v-row>
                  <v-col cols="6" md="6" sm="6"
                    ><v-radio value="hijri" :label="$i18n.t('Hijri')"></v-radio
                  ></v-col>
                  <v-col cols="6" md="6" sm="6"
                    ><v-radio
                      value="gregorian"
                      :label="$i18n.t('Gregorian')"
                    ></v-radio
                  ></v-col>
                </v-row>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6" style="">
              <div>
                <p class="value">{{ $t("End Contract Date") }}</p>
              </div>
              <v-text-field
                id="contract_end_date"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="ContractObject.contract_end_date_type == 'gregorian'"
                v-model.trim="ContractObject.contract_end_date"
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="contract_end_date_hijri"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="ContractObject.contract_end_date_type == 'hijri'"
                v-model.trim="ContractObject.contract_end_date_hijri"
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Durations and probation end data -->
      <v-row class="form-item">
        <!-- Duration -->
        <v-col cols="12" md="4" style="margin-top: 10px">
          <div>
            <p class="value">
              {{ $t("Duration") }}
              <span style="color: #888"> ( {{ $t("Days") }} ) </span>
            </p>
          </div>
          <v-text-field
            v-model="ContractObject.duration"
            :rules="[
              validationRules.positiveFloatNumber,
              validationRules.required,
            ]"
            solo
          ></v-text-field>
        </v-col>
        <!-- Date -->
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="12" md="6" style="margin-top: 30px">
              <v-radio-group
                v-model="ContractObject.contract_probationdate_end_data_type"
                :rules="[validationRules.required]"
              >
                <v-row>
                  <v-col cols="6" md="6" sm="6"
                    ><v-radio value="hijri" :label="$i18n.t('Hijri')"></v-radio
                  ></v-col>
                  <v-col cols="6" md="6" sm="6"
                    ><v-radio
                      value="gregorian"
                      :label="$i18n.t('Gregorian')"
                    ></v-radio
                  ></v-col>
                </v-row>
              </v-radio-group>
            </v-col>

            <v-col cols="12" md="6" style="">
              <div>
                <p class="value">{{ $t("Probation end date") }}</p>
              </div>
              <v-text-field
                id="contract_probationdate_end_data"
                append-icon="calendar_today"
                :rules="[
                  validationRules.required,
                  startDateRule(
                    ContractObject.contract_start_date,
                    ContractObject.contract_probationdate_end_data,
                    $i18n.t('End probation date should be more than start date')
                  ),
                ]"
                v-show="
                  ContractObject.contract_probationdate_end_data_type ==
                    'gregorian'
                "
                v-model.trim="ContractObject.contract_probationdate_end_data"
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>

              <v-text-field
                id="contract_probationdate_end_data_hijri"
                append-icon="calendar_today"
                :rules="[validationRules.required]"
                v-show="
                  ContractObject.contract_probationdate_end_data_type == 'hijri'
                "
                v-model.trim="
                  ContractObject.contract_probationdate_end_data_hijri
                "
                autocomplete="off"
                solo
                @keydown.prevent
                @paste.prevent
                @drop.prevent
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- check in mobile -->
      <v-row class="form-item">
        <v-col cols="12" md="12">
          <v-checkbox
            v-model="ContractObject.allow_mobile_check_in"
            :label="$i18n.t('Allow Mobile check-in')"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- Actions -->
      <v-row>
        <v-col cols="12" md="4" style="text-align: left !important"
          ><v-btn
            class="main-color"
            style="padding: 12px 30px"
            @click="previous()"
            >{{ $t("Previous") }}</v-btn
          ></v-col
        >
        <v-col cols="12" md="8" class="text-right">
          <v-btn
            class="modal-btn-cancel"
            @click="cancel()"
            style="padding: 12px 30px"
            >{{ $t("Cancel") }}</v-btn
          >
          <!-- <button class="ui-btn submit" @click="saveSalary()">Next</button> -->
          <v-btn
            class="modal-btn-cancel"
            style="
              padding: 12px 30px;
              background-color: #7297fe;
              color: #ffffff !important;
            "
            @click="saveContract"
            :loading="loading"
            :disabled="loading"
            >{{ $t("Next") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar v-model="snackbar" color="red" :top="true" :right="true">
      {{ text }}
      <v-btn color="red" text @click="snackbar = false">
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
require("moment/locale/es"); // without this line it didn't work
moment().format();
var moment_hijri = require("moment-hijri");
moment.locale("es");
// import ACL from "../../acl";
import { validationMixin } from "../../../mixins/validationMixin";
// import moment from "moment";
export default {
  mixins: [validationMixin],
  name: "Contract",
  props: ["ContractObject", "loading"],
  data() {
    return {
      valid: true,
      color: "red",
      snackbar: false,
      text: "Missing Inputs",
      shifts: [],
    };
  },
  watch: {
    "ContractObject.contract_date_signed": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.ContractObject.contract_date_signed_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.ContractObject.contract_date_signed_hijri = "";
      }
    },
    "ContractObject.contract_date_signed_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.ContractObject.contract_date_signed = h.format("DD/MM/YYYY");
      } else {
        this.ContractObject.contract_date_signed = "";
      }
    },

    "ContractObject.contract_start_date": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.ContractObject.contract_start_date_hijri = m.format(
          "iDD/iMM/iYYYY"
        );
      } else {
        this.ContractObject.contract_start_date_hijri = "";
      }
    },
    "ContractObject.contract_start_date_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.ContractObject.contract_start_date = h.format("DD/MM/YYYY");
      } else {
        this.ContractObject.contract_start_date = "";
      }
    },

    "ContractObject.contract_end_date": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.ContractObject.contract_end_date_hijri = m.format("iDD/iMM/iYYYY");
      } else {
        this.ContractObject.contract_end_date_hijri = "";
      }
    },
    "ContractObject.contract_end_date_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.ContractObject.contract_end_date = h.format("DD/MM/YYYY");
      } else {
        this.ContractObject.contract_end_date = "";
      }
    },

    "ContractObject.contract_probationdate_end_data": function(v) {
      if (v != "") {
        var m = moment_hijri(v, "DD/MM/YYYY");
        this.ContractObject.contract_probationdate_end_data_hijri = m.format(
          "iDD/iMM/iYYYY"
        );

        var split_start = this.ContractObject.contract_start_date.split("/");
        var date1 = new Date(
          split_start[2],
          split_start[1] - 1,
          split_start[0]
        );
        var split_end = v.split("/");
        var date2 = new Date(split_end[2], split_end[1] - 1, split_end[0]);
        var Difference_In_Time = date2.getTime() - date1.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        this.ContractObject.duration = Difference_In_Days;
      } else {
        this.ContractObject.contract_probationdate_end_data_hijri = "";
      }
    },
    "ContractObject.contract_probationdate_end_data_hijri": function(v) {
      if (v != "") {
        var h = moment_hijri(v, "iDD/iMM/iYYYY");
        this.ContractObject.contract_probationdate_end_data = h.format(
          "DD/MM/YYYY"
        );
      } else {
        this.ContractObject.contract_probationdate_end_data = "";
      }
    },
    "ContractObject.duration": {
      handler() {
        console.log("kkk");
        let v = this.ContractObject.duration;
        let start_date = this.ContractObject.contract_start_date;
        // let date = new Date();
        var new_date = moment(start_date, "DD-MM-YYYY").add(v, "days");

        this.ContractObject.contract_probationdate_end_data = new_date.format(
          "DD/MM/YYYY"
        );
      },
      deep: true,
    },
  },
  methods: {
    getShifts() {
      axios
        .get(this.getApiUrl + "/employee/getShifts", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
            //the token is a variable which holds the token
          },
        })
        .then((response) => {
          this.shifts = response.data.data;
          // console.log(response);
        });
    },
    cancel() {
      this.$router.push(
        {
          path: "/employees",
        },
        () => {}
      );
    },
    previous() {
      this.$emit("prevTab", 3);
    },

    saveContract() {
      if (this.valid == false) {
        this.validate();
        this.snackbar = true;
      } else {
        this.$emit("tabIsValid", 4);
      }
    },
    activateDatePicker() {
      let _this = this;

      $(document).ready(function() {
        $(
          "#contract_date_signed,#contract_start_date,#contract_end_date,#contract_probationdate_end_data"
        ).calendarsPicker({
          dateFormat: "dd/mm/yyyy",
          onSelect: function(date) {
            if (date[0]) {
              var dateH =
                date[0]._day + "/" + date[0]._month + "/" + date[0]._year;

              if ($(this).attr("id") == "contract_date_signed") {
                _this.ContractObject.contract_date_signed = dateH;
              }
              if ($(this).attr("id") == "contract_start_date") {
                _this.ContractObject.contract_start_date = dateH;
              }
              if ($(this).attr("id") == "contract_end_date") {
                _this.ContractObject.contract_end_date = dateH;
              }
              if ($(this).attr("id") == "contract_probationdate_end_data") {
                _this.ContractObject.contract_probationdate_end_data = dateH;
              }
            } else {
              if ($(this).attr("id") == "contract_date_signed") {
                _this.ContractObject.contract_date_signed = "";
              }
              if ($(this).attr("id") == "contract_start_date") {
                _this.ContractObject.contract_start_date = "";
              }
              if ($(this).attr("id") == "contract_end_date") {
                _this.ContractObject.contract_end_date = "";
              }
              if ($(this).attr("id") == "contract_probationdate_end_data") {
                _this.ContractObject.contract_probationdate_end_data = "";
              }
            }
          },
        });
        $(document).ready(function() {
          $(
            "#contract_date_signed_hijri,#contract_start_date_hijri,#contract_end_date_hijri,#contract_probationdate_end_data_hijri"
          ).calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            calendar: $.calendars.instance("islamic"),
            onSelect: function(date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                if ($(this).attr("id") == "contract_date_signed_hijri") {
                  _this.ContractObject.contract_date_signed_hijri = dateH;
                }
                if ($(this).attr("id") == "contract_start_date_hijri") {
                  _this.ContractObject.contract_start_date_hijri = dateH;
                }
                if ($(this).attr("id") == "contract_end_date_hijri") {
                  _this.ContractObject.contract_end_date_hijri = dateH;
                }
                if (
                  $(this).attr("id") == "contract_probationdate_end_data_hijri"
                ) {
                  _this.ContractObject.contract_probationdate_end_data_hijri = dateH;
                }
                // _this.generalInfoObj.date_of_birth_hijri = dateH;
              } else {
                if ($(this).attr("id") == "contract_date_signed_hijri") {
                  _this.ContractObject.contract_date_signed_hijri = "";
                }
                if ($(this).attr("id") == "contract_start_date_hijri") {
                  _this.ContractObject.contract_start_date_hijri = "";
                }
                if ($(this).attr("id") == "contract_end_date_hijri") {
                  _this.ContractObject.contract_end_date_hijri = "";
                }
                if (
                  $(this).attr("id") == "contract_probationdate_end_data_hijri"
                ) {
                  _this.ContractObject.contract_probationdate_end_data_hijri =
                    "";
                }
              }
            },
          });
        });
      });
    },
  },

  mounted() {
    this.getShifts();
    this.activateDatePicker();
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/_variables.scss";
@import "../../../styles/_forms.scss";
</style>
