var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"generalInfo"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveSalary.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"form-item"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',[_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t("Basic Salary")))])]),_c('v-text-field',{attrs:{"rules":[
            _vm.validationRules.required,
            _vm.validationRules.floatNumberNotZero,
          ],"solo":""},model:{value:(_vm.SalaryObject.basic_salary),callback:function ($$v) {_vm.$set(_vm.SalaryObject, "basic_salary", $$v)},expression:"SalaryObject.basic_salary"}})],1)],1),_c('v-row',{staticClass:"form-item"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',[_c('p',{staticClass:"value"},[_vm._v(_vm._s(_vm.$t("Allowances")))])]),_c('v-autocomplete',{attrs:{"items":_vm.allowancesItems,"item-text":"name","item-value":"id","multiple":"","chips":"","rules":[_vm.validationRules.required],"solo":""},on:{"change":function($event){_vm.hideLabel = true && _vm.onSelect(_vm.SalaryObject.allowaIds)}},model:{value:(_vm.SalaryObject.allowaIds),callback:function ($$v) {_vm.$set(_vm.SalaryObject, "allowaIds", $$v)},expression:"SalaryObject.allowaIds"}})],1)],1),(_vm.SalaryObject.allowancesEmployee.length > 0)?_c('div',[_c('v-row',{staticClass:"form-item"},_vm._l((_vm.SalaryObject.allowancesEmployee),function(allowance,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"3"}},[_c('div',[_c('span',{staticClass:"allowance-title"},[_vm._v(_vm._s(allowance.name))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[(allowance.account_type == 'amount')?_c('v-text-field',{staticClass:"allowance-input",attrs:{"rules":[
                    _vm.validationRules.required,
                    _vm.validationRules.numericNotZero,
                    _vm.validationRules.maxLength10,
                  ]},model:{value:(allowance.value),callback:function ($$v) {_vm.$set(allowance, "value", $$v)},expression:"allowance.value"}}):_c('v-text-field',{staticClass:"allowance-input",attrs:{"rules":[
                    _vm.validationRules.required,
                    _vm.validationRules.percentage,
                  ]},model:{value:(allowance.value),callback:function ($$v) {_vm.$set(allowance, "value", $$v)},expression:"allowance.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[(allowance.account_type == 'amount')?_c('span',{staticClass:"type"},[_vm._v(_vm._s(_vm.$t("SAR")))]):_c('span',{staticClass:"type"},[_vm._v("%")])])],1)],1)])}),1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"left !important"},attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"main-color",staticStyle:{"padding":"12px 30px"},on:{"click":function($event){return _vm.previous()}}},[_vm._v(_vm._s(_vm.$t("Previous")))])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"8"}},[_c('v-btn',{staticClass:"modal-btn-cancel",staticStyle:{"padding":"12px 30px"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c('v-btn',{staticClass:"modal-btn-cancel",staticStyle:{"padding":"12px 30px","background-color":"#7297fe","color":"#ffffff !important"},on:{"click":_vm.saveSalary}},[_vm._v(_vm._s(_vm.$t("Next")))])],1)],1)],1),_c('v-snackbar',{attrs:{"color":"red","top":true,"right":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }